* {
  margin: 0;
  padding: 0;
  max-width: 100%;

}

.wrapper {
  margin: 40px;
  max-width: 100%;
}

.first-wrapper {
  margin-bottom: 70px;
}

.first-wrapper h1{
  font-size: 18px;
  font-weight:550;
  margin-bottom: 9px;
  max-width: 100%;
}

.first-wrapper  .steps p{
  margin: 5px;
  font-size: 13px;
  color: #767474;

}

.askquestion {
  max-width: 100%;
  border-radius: 10px;
  margin: 0px 5vw 20px;
  padding:50px;
  box-shadow: 5px 2px 2px rgba(0, 0, 0, 0.1) ; 
  text-align: center;
}

.p-wrapper{
  display: flex;
  justify-content: space-around;
}

.askquestion h4 {
  text-align: center;
  margin-bottom: 6px;
  font-size: 20px;
  line-height: 50px;
  color: rgb(54, 53, 54);
}

.intenal-steps-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.steps {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content:space-evenly;
}

.input1 {
  border: 1.12px solid #cac7c7;
  outline: none;
  border-radius: 5px;
  width: 100%;
  height: 25px;
  padding: 10px 0px ;
  padding-left: 20px;
  margin: 15px 0;
}

.input1:focus{
  border: 1.12px solid #cac7c7;
}

.go:hover{
  cursor: pointer;
}

.area {
  width:100%;
  height: 30vh;
  font-family:sans-serif;
  border: 1.1px solid #cac7c7;
  border-radius: 6px;
  outline: none;
  padding: 10px 0px;
  padding-left: 20px;
}

.title{
text-align: center;
}

.area:focus{
  border: 1.12px solid #cac7c7;
}

.area::placeholder {
  font-size: 13px;
  font-weight: normal;
  color: #948f8f;
  font-family: "monteserrat", sans-serif;
    }

.go {
  font-size: 12px;
  font-weight: normal;
  color: #626161;
}

.post{
  margin-top: 10px;
  border-radius: 3px;
  background-color: rgb(81, 108, 240);
  height: 30px;
}

.button-wrapper{
  display: flex;
   justify-content:left;
}

.post:hover{
  background-color: rgb(254, 132, 2);
}

@media(max-width:600px) {
  .askquestion{
    padding: 20px;
  }
  .ask{
    font-size: 3vw;
  }

}
